import axios from "axios";
import { CHAT_API_BASE_URL } from "./constants.js";

export const fetchCategories = async (selectedStores = []) => {
    const { data } = await axios.get("/api/products/categories", {
        params: {
            stores: selectedStores,
        },
    });
    return data;
};

export const fetchStores = async () => {
    const { data } = await axios.get("/api/products/stores");
    return data;
};

export const fetchOffers = async () => {
    const { data } = await axios.get("/api/products/offers");
    return data;
};

export const fetchProductDetails = async (productId) => {
    const { data } = await axios.get(`/api/products/${productId}`);
    return data;
};

export const fetchProducts = async (filters, productsLimit) => {
    const {
        selectedBrands,
        selectedMainCategories,
        selectedSubCategories,
        selectedStores,
        priceRange,
        search,
        page,
        sortBy,
        order,
        lang,
        condition,
        sourcePage,
    } = filters;

    const { data } = await axios.get("/api/products", {
        params: {
            brands: selectedBrands,
            // check if the selectedMainCategories = [0] then send empty array
            mainCategories:
                selectedMainCategories.length === 1 &&
                selectedMainCategories[0] === 0
                    ? []
                    : selectedMainCategories,
            subCategories: selectedSubCategories,
            stores: selectedStores,
            priceRange,
            search,
            page,
            limit: productsLimit,
            sortBy,
            order,
            lang,
            condition,
            sourcePage,
        },
    });
    return data;
};

export const fetchBrands = async (state) => {
    const { data } = await axios.get("/api/products/brands", {
        params: {
            mainCategories:
                state.selectedMainCategories.length === 1 &&
                state.selectedMainCategories[0] === 0
                    ? []
                    : state.selectedMainCategories,
            subCategories: state.selectedSubCategories,
            stores: state.selectedStores,
        },
    });
    return data;
};

export const fetchPriceRange = async (state) => {
    const { data } = await axios.get("/api/products/v2/price-range", {
        params: {
            mainCategories:
                state.selectedMainCategories.length === 1 &&
                state.selectedMainCategories[0] === 0
                    ? []
                    : state.selectedMainCategories,
            subCategories: state.selectedSubCategories,
            stores: state.selectedStores,
            brands: state.selectedBrands,
        },
    });
    return data;
};

export const fetchProductsCompare = async (productsId) => {
    const { data } = await axios.get("/api/products/compare", {
        params: {
            products: productsId,
        },
    });
    return data;
};

export const sendMessage = async (message, sessionId, onStreamUpdate) => {
    try {
        const response = await fetch(`${CHAT_API_BASE_URL}/chat`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                message,
                session_id: sessionId,
                streaming: true,
            }),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedText = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            accumulatedText += chunk;

            // Process each chunk line by line
            const lines = accumulatedText.split("\n");
            accumulatedText = lines.pop(); // Keep the last incomplete chunk

            for (const line of lines) {
                if (line.trim()) {
                    try {
                        const parsed = JSON.parse(line);
                        console.log("Received chunk:", parsed); // Debugging log

                        // Use parsed.response instead of parsed.text
                        onStreamUpdate(parsed);
                    } catch (error) {
                        console.error("Error parsing chunk:", error);
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error sending message:", error);
        throw error;
    }
};

/*
// get similar products by brand and category without using models
export const getSimilarProducts = async (brandId, categoryId) => {
    const { data } = await axios.get("/api/products", {
        params: {
            brands: [brandId],
            subCategories: [categoryId],
            limit: 4,
        },

    });
    return data;
};
*/

export const sendUsageStatics = async (usageStatics) => {
    const { data } = await axios.post("/api/products/usage-statics", usageStatics);
    return data;
};
